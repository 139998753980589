import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
// import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import FunctionsIcon from '@mui/icons-material/Functions';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import HomePage  from './pages/HomePage';
import QuantraPage  from './pages/QuantraPage';

const NAVIGATION = [
  // {
  //   kind: 'header',
  //   title: 'Main items',
  // },
  {
    segment: 'page',
    title: 'Page',
    icon: <DashboardIcon />,
  },
  {
    segment: 'page-2',
    title: 'Page 2',
    icon: <TimelineIcon />,
  },
  {
    segment: 'quantra',
    title: 'Quantra',
    icon: <FunctionsIcon />,
  },
];

const appTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function AppPageContent({ pathname }) {
  console.log(pathname)
  if (pathname === '/page') {
      return <HomePage />
  } else if (pathname === '/page-2') {

    return (
      
      <Box
        sx={{
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography>Dashboard content for {pathname}</Typography>
      </Box>
    );
  } else if (pathname === '/quantra') {
      return <QuantraPage />

  }
}

AppPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function App(props) {
  // const { window } = props;

  const [pathname, setPathname] = React.useState('/page');

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  // const demoWindow = window !== undefined ? window() : undefined;

  return (
    // preview-start
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={appTheme}
      // window={demoWindow}
    >
      <DashboardLayout>
        {/* <HomePage /> */}
        <AppPageContent pathname={pathname} />
        
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

// App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  // window: PropTypes.func,
// };

export default App;

