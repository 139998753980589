import * as React from 'react';
import { ChangeEvent, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';


function TradeLog(){

    // let today = new Date().toISOString().split('T')[0]
    // const url_prefix = "http://nyradar.in/quantra/db/"
    // const url_suffix = "/db.json"
    // // const url = "http://nyradar.in/quantra/db/"+today+"/db.json"
    // let url = url_prefix+today+url_suffix

    const [seed, setSeed] = useState(1);
    
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [dateText, setDateText] = useState("");
    const [dateStr, setDateStr] = useState(new Date().toISOString().split('T')[0]);
    // let today = new Date().toISOString().split('T')[0]
    const url_prefix = "http://nyradar.in/quantra/db/"
    const url_suffix = "/db.json"
    // const url = "http://nyradar.in/quantra/db/"+today+"/db.json"
    // let url = url_prefix+dateStr+url_suffix
    const [url, setUrl] = useState(url_prefix+dateStr+url_suffix);

    const handleChange = (event) => {
      // 👇 Store the input value to local state
      setDateStr(event.target.value);
      console.log(dateStr)
      setUrl(url_prefix+dateStr+url_suffix)
      console.log(url)
    };

    
    useEffect(() => {
      setUrl(url_prefix+dateStr+url_suffix);
      setLoading(true);
      // console.log({dateStr});
      // console.log({url});
      fetch(url)
      .then((response) => response.json())
      .then((json) => setLogs(json))
      .catch(error => console.error(error))
      .finally(() => {
          setLoading(false);
      });

    }, [url,seed]);

    return (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <h1>Trade Logs</h1>
              <div style={{ padding: 20 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={5}>
                    <Button 
                    variant="contained" 
                    onClick={() => {
                      // console.log({dateStr})
                      // console.log({url})
                      setSeed(Math.random());
                    }}
                    startIcon={<RefreshIcon />}>
                      Refresh
                    </Button>
                
                    <TextField 
                    id="date-field" 
                    label="Date" 
                    defaultValue={dateStr}
                    onChange={handleChange}
                    size="small"
                    variant="outlined" />
                  </Grid>
                </Box>
              </div>
              
              
              <table border={1}>
                <tr>
                  <th>Date</th>
                  <th>Message</th>
                </tr>
                {

                }
                {logs.toReversed().map((log) => (
                  <tr>
                    <td>{log.date_time}</td>
                    <td>{log.message}</td>
                  </tr>
                ))}
              </table>
            </>
          )}
        </div>
      );
}

    


//  export default JsonDataDisplay;
export default function QuantraPage() {
    return (
        
        // <TradeLog key={seed}/>
        <TradeLog />
        // <JsonDataDisplay />
        // {JsonDataDisplay}
        // {Nifty }
        // <Nifty />
    );
  }

